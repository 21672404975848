<script>
import Swal from "sweetalert2";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import takeawayMealsRequestsTable from "./components/takeawayMealsRequestsTable";
import Multiselect from 'vue-multiselect'
import approvalModal from "./components/approveRequestModal";
import rejectionModal from "../../common/modals/rejectionModal";
import SideButtons from '@/components/side-buttons.vue';
import {
  fetchUserStructuresTypesApi,
  fetchDirectionsListApi,
  fetchDivisionsListApi,
  fetchDepartmentsListApi,
  fetchServicesListApi,
  fetchSectionsListApi,
  fetchTakeAwayMealRequestDestinationsApi,
  fetchTakeAwayMealRequestExternalDestinationsApi,
  fetchMealsTypesApi,
  fetchProductsApi
} from "@/api/common";
export default {
    page: {
        title: "Base de vies de l'entreprise",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: { Layout, PageHeader ,Multiselect  , takeawayMealsRequestsTable , approvalModal ,rejectionModal , SideButtons},
    import : {
      fetchUserStructuresTypesApi,
      fetchDirectionsListApi,
      fetchDivisionsListApi,
      fetchDepartmentsListApi,
      fetchServicesListApi,
      fetchSectionsListApi,
      fetchTakeAwayMealRequestDestinationsApi,
      fetchTakeAwayMealRequestExternalDestinationsApi,
      fetchMealsTypesApi,
      fetchProductsApi
    },
    data() {
    return {
      tableData: [],
      reloadDotationDone : undefined,
      List_Dotations: [],
      active:false,
      selectedRows:[],
      ODS:undefined,
      title: "Repas emportés",
      items: [
        {
          text: "Requêtes",
          href: "javascript:void(0)",
        },
        {
          text: "Repas emportés",
          active: true,
        },
      ],
      reloadOrders:undefined,
      showForm : false,
      add:false,
      save:false,
      saveToPending:false,
      confirm:false,
      validate:false,
      edit:false,
      SaveEdit:false,
      destroy:false,
      send:false,
      disabled:false,
      resetForm:false,
      have:false,
      reject:false,
      reset:false,
      canEdit :true,
      // form data 
       destinationTypes: [],
      externalDestinations: [],
      structureTypes: [],
      divisions: [],
      directions: [],
      departments: [],
      sections: [],
      services: [],
      structures: [],
      identityDocTypes: [],
      supportTypes: [],
      mealsTypes: [],
      products : [],
      uuid : undefined,
      dotationRequestForm: {
        uuid: "",
        ref : "",
        date: "",
        location_type: "",
        structure_type: "",
        structure: "",
        externalDestination:"",
        externalDestinationTxt:"",
        reason: "",
        analyticAccount: "",
        file: "",
        notes: "",
        products: [
          {
            product: "",
            qty: "",
            description: "",
          },
        ],
        requestedBy:"",
        status:"",
        approvedAt : "",
        approvedBy : "",
        rejectedBy : "",
        rejectionReason : "",
        rejectionDate : "",
        reception  : "",
        statusPlain : "",
      },
    };
  },
  
  validations: {
    dotationRequestForm: {
      // date: { required },
    },
  },
  mounted(){
    this.fetchProducts();
    this.fetchExternalDestinations();
    this.fetchUserStructuresTypes();
    this.fetchDirectionsList();
    this.fetchDivisionsList();
    this.fetchDepartmentsList();
    this.fetchServicesList();
    this.fetchSectionList();
    this.fetchTakeAwayMealRequestDestinations();
  },
  watch:{
    add:{
      handler(val){
        if(val){
          this.have = true;
          this.canEdit = true;
          this.showForm = true;
          this.resetForm = false;
          this.clearForms();
        }
      }
    },

    edit:{
      handler(val){
        if(val){
          this.have = true;
          this.canEdit = true;
          this.showForm = true;
          // this.resetForm = false;
        }
      }
    },
    destroy:{
      handler(val){
        if(val){
         this.deleteRequest();
          // this.resetForm = false;
        }
      }
    },
    reloadDotation :{
      handler(val){
       
      }
    },
      selectedRows(){
        // console.log(this.selectedRows);
      },
  },
  methods:{
    
    cancelBtn(){
      this.showForm  = false;
      this.have      = false;
      this.canEdit   = false;
      this.showForm  = false;
      this.resetForm = true;
    },
    selectedOrders(selectedOrders){
      // this.showForm = false;
      this.edit      = false;
      this.canEdit   = true;
      this.clearForms();
      if(selectedOrders.length> 0 &&  undefined != selectedOrders[0]){
        this.dotationRequestForm.ref           = selectedOrders[0].ref;
        this.dotationRequestForm.date          = selectedOrders[0].date;
        this.dotationRequestForm.location_type = selectedOrders[0].locationObj;
        if((selectedOrders[0].locationObj)['id']== 'internal'){
          this.dotationRequestForm.structure_type = this.structureTypes.filter(x => ( x.id == selectedOrders[0].structure_type))[0];
          this.onchangeStructureType();
          this.dotationRequestForm.structure      = this.structures.filter(x => ( x.id == selectedOrders[0].structure_id))[0];
        }
        this.dotationRequestForm.externalDestination    = selectedOrders[0].locationTypeObj;
        this.dotationRequestForm.externalDestinationTxt = selectedOrders[0].location_txt;
        this.dotationRequestForm.reason                 = selectedOrders[0].reason;
        this.dotationRequestForm.analyticAccount        = selectedOrders[0].analyticAccount;
        this.dotationRequestForm.file                   = selectedOrders[0].file;
        this.dotationRequestForm.notes                  = selectedOrders[0].notes;
        this.dotationRequestForm.products               = selectedOrders[0].entries;
        this.dotationRequestForm.uuid                   = selectedOrders[0].uuid;
        this.dotationRequestForm.requestedBy            = selectedOrders[0].requestedBy;
        this.dotationRequestForm.status                 = selectedOrders[0].status;
        this.dotationRequestForm.approvedBy             = selectedOrders[0].approvedBy;
        this.dotationRequestForm.rejectedBy             = selectedOrders[0].rejectedBy;
        this.dotationRequestForm.rejectionReason        = selectedOrders[0].rejectionReason;
        this.dotationRequestForm.rejectionDate          = selectedOrders[0].rejectionDate;
        this.dotationRequestForm.statusPlain            = selectedOrders[0].statusPlain;
        this.dotationRequestForm.approvedAt             = selectedOrders[0].approvedAt;
        this.dotationRequestForm.reception              = selectedOrders[0].reception;

        this.uuid     = selectedOrders[0].uuid;
        this.showForm = false;
        this.have     = false;
        this.canEdit  = false;

      }
    },
    clearForms(){
        this.dotationRequestForm.ref                    = "";
        this.dotationRequestForm.uuid                   = "";
        this.dotationRequestForm.date                   = "";
        this.dotationRequestForm.location_type          = "";
        this.dotationRequestForm.structure_type         = "";
        this.dotationRequestForm.structure              = "";
        this.dotationRequestForm.externalDestination    = "";
        this.dotationRequestForm.externalDestinationTxt = "";
        this.dotationRequestForm.reason                 = "";
        this.dotationRequestForm.analyticAccount        = "";
        this.dotationRequestForm.file                   = "";
        this.dotationRequestForm.notes                  = "";
        this.dotationRequestForm.products               = [
          {
            product    : "",
            qty        : "",
            description: "",
          },
        ];
        this.dotationRequestForm.requestedBy            = "";
        this.dotationRequestForm.statusPlain            = "";
        this.dotationRequestForm.status                 = "";
        this.dotationRequestForm.approvedBy             = "";
        this.dotationRequestForm.rejectedBy             = "";
        this.dotationRequestForm.rejectionReason        = "";
        this.dotationRequestForm.reception              = "";
        this.dotationRequestForm.approvedAt             = "";
        this.dotationRequestForm.rejectionDate          = "";

         this.uuid  = undefined;
    },
     onchangeStructureType(){
      var struct      = this.dotationRequestForm.structure_type.id;
      this.structures = [];
      switch (struct) {
        case 'direction':
          this.structures = this.directions;
        break;

        case 'division':
          this.structures = this.divisions;
        break;

        case 'department':
          this.structures = this.departments;
        break;

        case 'service':
          this.structures = this.services;
        break;

        case 'section':
          this.structures = this.sections;
        break;
      
        default:
          
          break;
      }
    },
    _validateDotationProductsData(){
      var valid    = true;
      var products = this.dotationRequestForm.products;
      products.forEach((singleProdEntry, index) => {
        index++;
          if(singleProdEntry.product == ""){
            this.$toast.warning("Veuillez choisir un produit dans la line N°: " + index);
            valid = false;
          }

          if(singleProdEntry.qty == ""){
            this.$toast.warning("Veuillez choisir la quantité dans la line N°: " + index);
            valid = false;
          }
      });

      return valid;
    },
    AddformData() {
      if(this._validateDotationProductsData()){
        this.dotationRequestForm.products.push({
          product    : "",
          qty        : "",
          description: "",
        });
      }
    },
    fetchProducts(){
      fetchProductsApi()
      .then((res) => {
        this.products = res.data.data
        })
      .catch(() => {})
      .finally(() => {});
    },
    fetchExternalDestinations(){
      fetchTakeAwayMealRequestExternalDestinationsApi()
      .then((res) => (this.externalDestinations = res.data.original.data))
      .catch(() => {})
      .finally(() => {});
    },
    fetchTakeAwayMealRequestDestinations(){
      fetchTakeAwayMealRequestDestinationsApi()
      .then((res) => (this.destinationTypes = res.data.original.data))
      .catch(() => {})
      .finally(() => {});
    },
    fetchDirectionsList(){
      fetchDirectionsListApi()
      .then((res) => (this.directions = res.data))
      .catch(() => {})
      .finally(() => {});
    },
    fetchDivisionsList(){
      fetchDivisionsListApi()
      .then((res) => (this.divisions = res.data))
      .catch(() => {})
      .finally(() => {});
    },
    fetchDepartmentsList(){
      fetchDepartmentsListApi()
      .then((res) => (this.departments = res.data))
      .catch(() => {})
      .finally(() => {});
    },
    fetchServicesList(){
      fetchServicesListApi()
      .then((res) => (this.services = res.data))
      .catch(() => {})
      .finally(() => {});
    },
    fetchSectionList(){
      fetchSectionsListApi()
      .then((res) => (this.sections = res.data))
      .catch(() => {})
      .finally(() => {});
    },
    fetchUserStructuresTypes() {
      fetchUserStructuresTypesApi()
      .then((res) => (this.structureTypes = res.data.original.data))
      .catch(() => {})
      .finally(() => {});
    },
    deleteRow(index) {
      if (confirm("Êtes-vous sûr de vouloir supprimer ce produit?"))
        this.dotationRequestForm.products.splice(index, 1);
    },
    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      let loader = this.$loading.show({
          color: '#000000',
          loader: 'spinner',
          width: 64,
          height: 64,
          backgroundColor: '#ffffff',
          opacity: 0.5,
          zIndex: 999,
      })
      this.$http.post("/ops/requests/dotation/store",this.dotationRequestForm)
        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              this.reloadOrders = true;
              this.clearForms();
              this.lastSelected   = null;
              this.showForm = false;
              this.canEdit  = false;
              this.have     = false;
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }          
          loader.hide();
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
      deleteRequest(){
      this.destroy = false;
      var ref      = this.dotationRequestForm.ref
      Swal.fire({
        title: "Êtes-vous sûr de supprimer la requet  : " + ref + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/ops/requests/dotation/delete/" + this.uuid )
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.reloadOrders = true;
                  this.clearForms();
                  this.lastSelected   = null;
                  this.showForm = false;
                  this.canEdit  = false;
                  this.have     = false;
                  break;
                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
  }
    
}
</script>
<template>
    <Layout>
    <PageHeader :title="title" :items="items" />
    
    <takeaway-meals-requests-table  :reloadOrders="reloadOrders" @selectedOrders="selectedOrders" @reloadOrdersDone="reloadOrders = $event" />
    <SideButtons  :reset="resetForm" :canEdit="canEdit || dotationRequestForm.statusPlain != 'draft'"
          :select="have" :save="send" :ODS="false" :service_order="false" @info ="info = $event" @add="add = $event" @save="save = $event" 
          @saveToPending="saveToPending = $event" @edit=" edit = $event" @SaveEdit="SaveEdit = $event" @confirm=" confirm = $event" 
          @reject="reject = $event" @validate=" validate = $event" @resetEmit="resetForm = $event" @delete=" destroy = $event" />
          
    <div class="row" v-show="showForm">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit" enctype="multipart/form-data">              
              <div class="row">
                <div class="col-xs-12 col-md-4 col-lg-6">
                  <div class="form-group">
                    <label for="formrow-password-input"
                      >Motif de la demande *</label
                    >
                    <input
                      v-model="dotationRequestForm.reason"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-inputCity">Date *</label>
                    <input type="date" v-model="dotationRequestForm.date" class="form-control" name="" id="">
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-inputCity">Destination *</label>
                    <multiselect
                      v-model="dotationRequestForm.location_type"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="destinationTypes"
                      placeholder="Select user structure"
                      :allow-empty="false"
                      :select-label="''"
                      :deselect-label="''"
                    >
                      <template slot="singleType" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                    
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-md-4 col-lg-6">
                  <div class="form-group">
                    <label for="">Imputation *</label>
                    <input type="text" class="form-control" v-model="dotationRequestForm.analyticAccount">
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-3" v-if="dotationRequestForm.location_type.id == 'external'">
                  <div class="form-group">
                    <label for="formrow-inputCity">Destination externe *</label>
                    <multiselect
                      v-model="dotationRequestForm.externalDestination"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="externalDestinations"
                      placeholder="Select user structure"
                      :allow-empty="false"
                      :select-label="''"
                      :deselect-label="''"
                    >
                      <template slot="singleDestination" slot-scope="{ destination }">{{
                        destination.name
                      }}</template>
                    </multiselect>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-3" v-if="dotationRequestForm.location_type.id == 'external'">
                  <div class="form-grou">
                    <label for="">Détails de Destination</label>
                    <input type="text" class="form-control" v-model="dotationRequestForm.externalDestinationTxt">
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-3" v-if="dotationRequestForm.location_type.id == 'internal'">
                  <div class="form-group">
                    <label for="formrow-inputCity">Type de structure *</label>
                    <multiselect
                      v-model="dotationRequestForm.structure_type"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="structureTypes"
                      placeholder="Select user structure"
                      :allow-empty="false"
                      @input="onchangeStructureType"
                      :select-label="''"
                      :deselect-label="''"
                    >
                      <template slot="singleStruct" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                  </div>
                </div>
         

                <div class="col-xs-12 col-md-4 col-lg-3" v-if="dotationRequestForm.location_type.id == 'internal'">
                  <div class="form-group">
                    <label for="formrow-password-input"
                      >Structure *</label
                    >
                    <multiselect
                      v-model="dotationRequestForm.structure"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="structures"
                      placeholder="Select user structure"
                      :allow-empty="false"
                      :select-label="''"
                      :deselect-label="''"
                    >
                      <template slot="singleStruct" slot-scope="{ struct }">{{
                        struct.name
                      }}</template>
                    </multiselect>
                  </div>
                </div>
                
                
              </div>

             <hr>  
              <div>
                <div
                  v-for="(requestMeals, index) in dotationRequestForm.products"
                  :key="requestMeals.id"
                  class="row"
                >
                  <div class="form-group col-lg-4">
                    <label for="resume">Produit</label>
                    <multiselect
                      v-model="requestMeals.product"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="products"
                      placeholder="Selectionner un produit"
                      :allow-empty="false"
                      :select-label="''"
                      :deselect-label="''"
                    >
                      <template slot="singleMealType" slot-scope="{ mealType }">{{
                        mealType.name
                      }}</template>
                    </multiselect>
                  </div>

                  <div class="form-group col-lg-3">
                    <label for="subject">Qty</label>
                    <input
                      id="subject"
                      v-model="requestMeals.qty"
                      type="number" min="1"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group col-lg-4">
                    <label for="message">Observations</label>
                    <input
                      id="subject"
                      v-model="requestMeals.description"
                      type="text"
                      class="form-control"
                    />
                  </div>

                  <div class="col-lg-1 align-self-center">
                    <a
                      v-if="
                        dotationRequestForm.products.length > 1 &&
                        index != 0
                      "
                      href="javascript:void(0)"
                      ><i class="bx bx-trash-alt" @click="deleteRow(index)"></i
                    ></a>
                  </div>
                </div>
                <input
                  type="button"
                  class="btn btn-success mt-3 mt-lg-0"
                  value="Ajouter"
                  @click="AddformData"
                />
              </div>
              <br />
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="formrow-inputCity">Commentaires</label>
                    <textarea
                      class="form-control"
                      v-model="dotationRequestForm.notes"
                      cols="30"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div>
                 <button type="button"  @click="cancelBtn" class="btn btn-light btn-label float-right ml-2">
                  <i class="fas fa-times text-white label-icon" ></i> Annuler
                </button>
                
                <button type="submit" :disabled="$v.dotationRequestForm.$invalid" class="btn btn-label btn-primary float-right" >
                  <i class="far fa-save label-icon "></i> Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div v-show="!showForm">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Détails
            <div class="button-items float-right mr-5" v-if="dotationRequestForm.statusPlain == 'draft' && $can('edit_vsr')">
              <b-button
                variant="success"  class="btn-label"
                v-b-modal.approvalModal 
                v-if="dotationRequestForm.statusPlain == 'draft' && $can('approve_vsr')"
              >
                <i
                  class="bx bx-check-double font-size-16 align-middle mr-2 text-white label-icon"
                ></i>
                Approuver
              </b-button>

              <b-button
                variant="danger" class="btn-label"
                v-b-modal.rejectionModal
                v-if="dotationRequestForm.statusPlain == 'draft' && $can('reject_vsr')"
              >
                <i class="bx bx-block font-size-16 align-middle mr-2 text-white label-icon"></i>
                Rejeter
              </b-button>
            </div>
            </h4>
            <p lass="text-muted mb-4">
            </p>
            <div class="row">
              <div class="col-md-6">
                <div class="table-responsive mb-0">
                  <table class="table">
                    <tbody>
                      <tr >
                        <th>Demandeur :</th>
                        <td>{{ dotationRequestForm.requestedBy }}</td>
                      </tr>
                      <tr v-if="dotationRequestForm.location_type.id == 'external'">
                        <th>Destination externe :</th>
                        <td>
                          {{ dotationRequestForm.externalDestination['name']}}
                        </td>
                      </tr>
                      <tr  v-if="dotationRequestForm.location_type.id == 'external'">
                        <th>Détails de Destination :</th>
                        <td>
                          {{ dotationRequestForm.externalDestinationTxt}}
                        </td>
                      </tr>
                      <tr v-if="dotationRequestForm.location_type.id == 'internal'">
                        <th>Type de structure  :</th>
                        <td>
                          {{ dotationRequestForm.structure_type['name']}}
                        </td>
                      </tr>
                      <tr  v-if="dotationRequestForm.location_type.id == 'internal'">
                        <th>Structure :</th>
                        <td>
                          {{ dotationRequestForm.structure['name']}}
                        </td>
                      </tr>
                      <tr >
                        <th>Imputation :</th>
                        <td>
                          <b>{{ dotationRequestForm.analyticAccount }}</b>
                        </td>
                      </tr>
                      <tr v-if="dotationRequestForm.statusPlain == 'rejected'">
                        <th>Rejeté par :</th>
                        <td>{{ dotationRequestForm.rejectedBy }}</td>
                      </tr>

                      <tr v-if="dotationRequestForm.statusPlain == 'approved'">
                        <th>Approuvée par :</th>
                        <td>{{ dotationRequestForm.approvedBy }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-6">
                <div class="table-responsive mb-0">
                  <table class="table">
                    <tbody>
                      <tr>
                        <th>Destination :</th>
                        <td>{{dotationRequestForm.location_type['name']}}</td>
                      </tr>
                      <tr>
                        <th>Date de demande :</th>
                        <td>{{ dotationRequestForm.date }}</td>
                      </tr>
                      <tr>
                        <th>Motif de la demande :</th>
                        <td>
                          {{ dotationRequestForm.reason }}
                        </td>
                      </tr>
                      <tr>
                        <th>Etat :</th>
                        <td>
                          <span v-html="dotationRequestForm.status"></span>
                        </td>
                      </tr>
                      <tr v-if="dotationRequestForm.statusPlain == 'rejected'">
                        <th>Date de rejet:</th>
                        <td>{{ dotationRequestForm.rejectionDate }}</td>
                      </tr>
                      <tr v-if="dotationRequestForm.statusPlain == 'rejected'">
                        <th>Raison de rejet:</th>
                        <td>{{ dotationRequestForm.rejectionReason }}</td>
                      </tr>

                      <tr v-if="dotationRequestForm.statusPlain == 'approved'">
                        <th>Approuvée Le :</th>
                        <td>{{ dotationRequestForm.approvedAt }}</td>
                      </tr>
                      <tr v-if="dotationRequestForm.statusPlain == 'approved' && dotationRequestForm.reception.length > 0">
                        <th>Bon de Livraison :</th>
                        <td>
                          
                            <router-link
                          :to="{
                            name: 'ops.receptions.display',
                            params: { uid: dotationRequestForm.reception.uuid },
                          }"
                          >{{ dotationRequestForm.reception.ref }}</router-link>
                          </td>
                      </tr>
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-lg-12">
                  <h4 class="card-title">Commentaires : </h4> <br>
                  <p class="card-title-desc">{{ dotationRequestForm.notes }}</p>

                  <div class="table-responsive">
                    <table class="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Produit</th>
                          <th>Qty</th>
                          <th>Observations</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="entry in dotationRequestForm.products" v-bind:key="entry.id">
                          <td>{{ entry.display_name }}</td>
                          <td>{{ entry.qty }} </td>
                          <td>{{ entry.description }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <approval-modal :requestData="dotationRequestForm"  :modelUid="dotationRequestForm.uuid" :modelRef="dotationRequestForm.ref" />
    <rejection-modal
      :modelRef="dotationRequestForm.ref"
      :modelId="dotationRequestForm.id"
      :modelType="dotationRequestForm.modelType"
    />
    </div>
    </Layout>
</template>
